import request from '@/services/axios_instance'

import Editor from '@/common/Editor/Editor.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Comment',
  components: {
    Editor,
    DeleteDialog
  },
  created() {
    this.$event.$on('new-comment', (comment) => {
      if (
        comment.commentable_id == this.id &&
        comment.causer_id != this.user.id &&
        comment.type == this.type
      ) {
        this.comments.unshift(comment)
        this.$nextTick(() => {
          this.scrollToRef('scrollTotop')
        })
      }
    })
    this.$event.$on('delete-comment', (comment) => {
      if (comment.commentable_id == this.id && comment.type == this.type) {
        let index = this.comments.findIndex(
          (i) => parseInt(i.id) === parseInt(comment.id)
        )
        if (~index) this.comments.splice(index, 1)
        this.$nextTick(() => {
          this.scrollToRef('scrollTotop')
        })
      }
    })
  },
  props: {
    id: { type: Number, required: true },
    type: { type: String, required: true },
    maxHeight: { type: [Number, String], default: 400 },
    minimizeEmptyComments: { type: Boolean, default: false },
    deletable: { type: Boolean, default: true },
    withAvatar: { type: Boolean, default: false }
  },

  data: () => ({
    delete_comment_dialog: false,
    comments: [],
    comment: '',
    isEmptyComment: true,
    attachment: null,
    btnloading: false,
    hover: false,
    activeComment: null,
    next_page_url: null,
    counterReset: 0
  }),
  watch: {
    id: {
      handler: function (val) {
        this.fetchComments()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    fetchApi() {
      return `api/comments/${this.id}/${this.type}`
    },
    deleteApi() {
      return `api/comments/${this.activeComment.id}/${this.type}`
    },
    addApi() {
      return `api/comments/${this.id}/${this.type}`
    }
  },
  methods: {
    can_delete_comment(comment) {
      if (!this.deletable) return false
      if (this.user.is_admin) return true
      return comment.causer.id === this.user.id
    },
    confirm_delete_comment(item) {
      this.activeComment = item
      this.delete_comment_dialog = true
    },
    addComment() {
      if (!this.comment || this.comment === '') {
        return
      }
      this.btnloading = true
      let payload = { comment: this.comment, body: this.comment }
      request
        .post(this.addApi, payload)
        .then(({ data }) => {
          this.comments.push(data)
          this.comment = ''
          this.counterReset += 1
        })
        .finally(() => (this.btnloading = false))
    },
    confirmed_delete_comment() {
      request
        .delete(this.deleteApi)
        .then(({ data }) => {
          let index = this.comments.findIndex(
            (cm) => cm.id === this.activeComment.id
          )
          if (~index) {
            this.comments.splice(index, 1)
            this.activeComment = null
          }
          this.delete_comment_dialog = false
        })
        .finally(() => (this.btnloading = false))
    },
    fetchComments() {
      this.loading = true
      request
        .get(this.fetchApi)
        .then(({ data }) => {
          this.comments = data.data
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading = false))
    },
    fetchMoreComments() {
      request.get(this.next_page_url).then(({ data }) => {
        this.comments.push(...data.data)
        this.next_page_url = data.next_page_url
      })
    }
  }
}
